<template>
  <div class="application">
    <header>
      <img src="~img/02.png">
      <img src="~img/60.png" class="zc">
      <div class="fanhou" @click="$router.go(-1)">
        <van-icon name="arrow-left" />
        <p>返回</p>
      </div>
    </header>
    <main>
      <ul>
        <li v-for="(item,index) in Form" :key="index">
          <div class="template-box" v-if="item.type === 'input'">
            <span class="biaoshi" v-if="item.required == 1">*</span>
            <input type="text" v-model="item.value" :placeholder="item.default_value || item.label">
          </div>
          <div class="template-box" v-if="item.type === 'select'" @click="changeSelect(item)">
            <span class="biaoshi" v-if="item.required == 1">*</span>
            <p>{{item.default_value || item.value || item.label}}</p>
            <van-icon name="arrow" size="15" color="#a4a4a4" />
          </div>
          <div class="template-box" v-if="item.type === 'upload-image'">
            <span class="biaoshi-top" v-if="item.required == 1">*</span>
            <div>
              <p>证件照片</p>
              <van-uploader v-model="fileList" :after-read="afterRead" :before-read="beforeRead" @delete='deleteFile'
                :click-upload='clickUploader(item)' />
            </div>
          </div>
        </li>
      </ul>
      <button class="register" @click="submit">提交报名</button>
    </main>
    <van-popup v-model="showPicker" round position="bottom">
      <van-picker show-toolbar :columns="columns" @cancel="showPicker = false" @confirm="onConfirm" />
    </van-popup>
    <van-overlay :show="show">
      <van-loading color="#1989fa" />
    </van-overlay>
  </div>
</template>


<script>
export default {
  data() {
    return {
      Form: [],
      showPicker: false,
      columns: [],
      selectValue: null,
      upValue: [],
      fileList: [],
      show: false,
    };
  },
  created() {
    this.Form = JSON.parse(localStorage.getItem("form"));
    this.id = this.getQueryValue("id");
    console.log(this.Form);
    this.Form.forEach((item) => {
      if (item.type === "upload-image") {
        item.value = [];
      } else {
        item.value = "";
      }
    });
  },
  methods: {
    changeSelect(item) {
      console.log(item);
      this.selectValue = item;
      this.columns = item.options.values;
      this.showPicker = true;
    },
    onConfirm(value) {
      // this.value = value;
      this.selectValue.value = value;
      this.showPicker = false;
    },
    clickUploader(item) {
      console.log(item);
      this.upValue = item;
    },
    beforeRead(file) {
      if (file.type.startsWith("image")) {
        return true;
      } else {
        this.$toast("请上传图片文件");
        return false;
      }
    },
    afterRead(file) {
      this.show = true;
      let formData = new FormData();
      formData.append("file", file.file);
      this.updata(formData);
    },
    async updata(file) {
      this.showLoading = true;
      const res = await this.axios.post("upload", file, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (res.code === 200) {
        this.show = false;
        this.upValue.value.push(res.data.url);
      } else {
        this.show = false;
        this.$toast(res.message);
      }
    },
    deleteFile(file, detail) {
      this.upValue.value.splice(detail.index, 1);
    },
    async submit() {
      for (let i = 0; i < this.Form.length; i++) {
        if (
          this.Form[i].required === "1" &&
          this.Form[i].value === "" &&
          this.Form[i].default_value === ""
        ) {
          this.$toast(this.Form[i].label + "必填");
          return false;
        }
      }
      this.Form.forEach((item) => {
        if (item.value === "" && item.default_value !== "") {
          item.value = item.default_value;
        }
      });
      this.show = true;
      const res = await this.axios.post(`user/activity`, {
        activity_id: this.id,
        form: this.Form,
      });
      console.log(res);
      if (res.code === 200) {
        this.show = false;
        this.$toast("报名成功");
        this.$router.push({ path: "/myActivity" });
      } else {
        this.show = false;
        this.$toast(res.message);
      }
    },
  },
};
</script>


<style lang="less" scoped>
.application {
  height: 100%;
  position: relative;
  header {
    position: relative;
    .zc {
      width: 390px;
      height: 117px;
      position: absolute;
      left: 70px;
      top: 96px;
    }
    .fanhou {
      position: absolute;
      top: 20px;
      left: 30px;
      color: #fff;
      font-size: 30px;
      display: flex;
      align-items: center;
    }
  }
  main {
    width: 92%;
    // height: 73%;
    padding-bottom: 40px;
    border-radius: 18px;
    background: #fff;
    position: absolute;
    top: 323px;
    left: 50%;
    transform: translate(-50%);
    padding-top: 20px;
    font-family: SourceHanSansCN-Normal, SourceHanSansCN;
    font-weight: 400;
    ul {
      li {
        border-bottom: 1px solid #ebebeb;
        min-height: 88px;
        background: #fff;
        width: 88%;
        margin: auto;
        position: relative;
        padding: 0;
        .template-box {
          width: 100%;
          min-height: 88px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          p {
            font-size: 28px;
            line-height: 88px;
            color: #a4a4a4;
          }

          .biaoshi {
            position: absolute;
            top: 50%;
            left: -25px;
            transform: translate(0, -40%);
            font-size: 24px;
            color: #dd1717;
          }
          .biaoshi-top {
            position: absolute;
            top: 25px;
            left: -25px;
            font-size: 24px;
            color: #dd1717;
          }
          input {
            width: 100%;
            font-size: 28px;
            height: 100%;
            color: #a4a4a4;
          }
          ::-webkit-input-placeholder {
            /* WebKit browsers，webkit内核浏览器 */
            color: #a4a4a4;
            font-family: SourceHanSansCN-Normal, SourceHanSansCN;
          }
          :-moz-placeholder {
            /* Mozilla Firefox 4 to 18 */
            color: #a4a4a4;
            font-family: SourceHanSansCN-Normal, SourceHanSansCN;
          }
          ::-moz-placeholder {
            /* Mozilla Firefox 19+ */
            color: #a4a4a4;
            font-family: SourceHanSansCN-Normal, SourceHanSansCN;
          }
          :-ms-input-placeholder {
            /* Internet Explorer 10+ */
            color: #a4a4a4;
            font-family: SourceHanSansCN-Normal, SourceHanSansCN;
          }
        }
      }
      li:last-child {
        border: none;
      }
    }
  }
  .register {
    width: 609px;
    height: 81px;
    background: url("../../assets/img/05.png") no-repeat;
    background-size: contain;
    font-size: 32px;
    color: #fff;
    display: block;
    margin: auto;
    margin-top: 120px;
  }
  .van-loading {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>